<template>
  <div class="login-page">
    <h1 class="login-title">WILSON INTERIOR CONTRACTING</h1>

    <big id="form-error" class="text-danger" v-if="error">
      {{ error }}
    </big>

    <div class="login-form">
      <div class="login-form-inner">
        <div class="form-group">
          <label>User Name</label>
          <input ref="username" type="text" class="form-control" placeholder="User Name">
        </div>
        <div class="form-group">
          <label>Password</label>
          <input ref="password" type="password" class="form-control" placeholder="Password">
        </div>
        <button @click="login" class="btn btn-black">Login</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Login',
  data(){
    return{
      error: null,
    }
  },
  mounted(){
    const loginToken = window.localStorage.getItem('loginToken');
    if(loginToken){
      this.startLogin();
      this.$router.push('/dashboard');
    }
  },
  methods: {
    async login(){
      const apiUrl = `${this.apiUrl}/login`;
      const username = this.$refs.username.value;
      const password = this.$refs.password.value;

      let loginData = {
        username,
        password,
      };

      const loginResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(loginData),
      });
      const status = await loginResult.status;

      if(status !== 200){
        this.error = 'Cannot be login. Please confirm your username and password is correct.';
        return;
      }
      const resultJson = await loginResult.json();
      const token = resultJson['token'];
      const userId = resultJson['user_id'];

      window.localStorage.setItem('loginToken', token);
      window.localStorage.setItem('userId', userId);

      this.startLogin();
      this.$router.push('/dashboard');
    },
    ...mapActions(['startLogin']),
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>


<style lang="scss">
#form-error{
  text-align: center;
}
.login-title{
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.login-logo{
  width: 200px;
}

#form-error{
  display: block;
  margin-top: 20px;
  margin-bottom: 50px;
}

.login-form{
  display: flex;
  justify-content: center;

  .login-form-inner{
    max-width: 500px;
    width: 100%;
  }

  .btn-black{
    margin-top: 15px;
  }
}
</style>
